<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Sale')} ${$t('To Edit')} | ${$t('Invoice')} #${saleData.id != null ? saleData.id : ''}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >

          <!-- Header -->
          <b-row>

            <!-- Field: Client -->
            <b-col
              cols="6"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="client"
                rules="required"
              >
                <b-form-group
                  :label="$t('Client')"
                  label-for="client"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="saleData.client_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clientOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="client"
                  >

                    <template #selected-option="{ label }">
                      <div>{{ truncateText(label) }}</div>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Total Amount -->
            <b-col
              cols="6"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="total-amount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Total Amount')"
                  label-for="total-amount"
                >
                  <b-form-input
                    id="total-amount"
                    v-model="saleData.total_amount"
                    class="plain border-light"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    readonly
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Discount -->
            <b-col
              cols="6"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="discount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Discount')"
                  label-for="discount"
                >
                  <b-form-input
                    id="discount"
                    v-model="saleData.discount"
                    :state="getValidationState(validationContext)"
                    type="number"
                    trim
                    placeholder=""
                    @focus="$event.target.select()"
                    @input="calTotalAmount()"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Net Amount -->
            <b-col
              cols="6"
              md="3"
            >
              <b-form-group
                :label="$t('Net')"
                label-for="net-amount"
                label-class="font-weight-bold"
              >
                <b-form-input
                  id="net-amount"
                  v-model="saleData.net_amount"
                  trim
                  placeholder=""
                  class="plain font-weight-bold border-light"
                  readonly
                />

              </b-form-group>
            </b-col>

          </b-row>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <section
            class="invoice-preview-wrapper"
          >
            <b-row class="mb-1">
              <!-- Field: Item -->
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  :label="$t('Select Item')"
                  label-for="item"
                >
                  <v-select
                    v-model="itemId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="item"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Quantity -->
              <b-col
                md="4"
              >
                <b-form-group
                  :label="$t('Quantity')"
                  label-for="quantity"
                >
                  <b-input-group>
                    <b-form-input
                      id="quantity"
                      ref="quantity"
                      v-model="itemQuantity"
                      type="number"
                      trim
                      placeholder=""
                      @keyup.enter="quantityEnter"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="addNewItem"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-50"
                        />{{ $t('Add') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>

                </b-form-group>

              </b-col>
            </b-row>

            <div
              ref="formitem"
              class="repeater-form"
              :style="{height: trHeight}"
            >
              <b-row
                v-for="(item, index) in saleData.sale_items"
                :key="index"
                ref="rowitem"
                class="pb-2"
              >

                <!-- Item Form -->
                <!-- ? This will be in loop => So consider below markup for single item -->
                <b-col cols="12">

                  <!-- Form Input Fields OR content inside bordered area  -->
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <div class="d-flex border rounded">

                    <b-row class="flex-grow-1 p-1">

                      <!-- Item Name -->
                      <b-col
                        cols="12"
                        lg="5"
                      >
                        <label class="d-inline">{{ $t('Item') }}</label>
                        <p class="mb-1">
                          {{ item.item_name }}
                        </p>
                      </b-col>

                      <!-- Price -->
                      <b-col
                        cols="6"
                        lg="3"
                        sm="4"
                      >
                        <label class="d-inline">{{ $t('Price') }}</label>
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="mb-1"
                          @input="calItemAmount(item.item_id, item.price, item.quantity)"
                        />
                      </b-col>

                      <!-- Quantity -->
                      <b-col
                        cols="6"
                        lg="2"
                        sm="4"
                      >
                        <label class="d-inline">{{ $t('Quantity') + '(' + item.unit + ')' }}</label>
                        <b-form-input
                          v-model="item.quantity"
                          type="number"
                          class="mb-1"
                          @input="calItemAmount(item.item_id, item.price, item.quantity)"
                        />
                      </b-col>

                      <!-- Item Amount -->
                      <b-col
                        cols="12"
                        lg="2"
                        sm="4"
                      >
                        <label class="d-inline">{{ $t('Amount') }}</label>
                        <b-form-input
                          v-model="item.amount"
                          type="text"
                          class="mb-1 plain border-light"
                          readonly
                        />
                      </b-col>
                    </b-row>

                    <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(index)"
                      />

                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>

          </section>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Form Actions -->
          <div class="d-flex flex-row mt-2">

            <b-button
              v-if="saleData.sale_items.length > 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              type="button"
              pill
              class="mr-1"
              :class="{'col-sm-6': isMobile}"
              @click="onSubmit"
            >
              {{ $t('Save') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="gradient-secondary"
              pill
              :class="{'col-sm-6': isMobile}"
              @click="close"
            >
              {{ $t('Close') }}
            </b-button>

          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import OfflineDB from '@/libs/offline-db'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import saleStoreModule from '../saleStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isMobile: false,
      required,
      itemOptions: [],
      clientOptions: [],
      itemId: null,
      itemQuantity: null,
      offlineDB: new OfflineDB(),
    }
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'saleData.sale_items': function () {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    'saleData.total_amount': function () {
      this.calTotalAmount()
    },
  },
  mounted() {
    this.updateScreenWidth()
    window.addEventListener('resize', this.updateScreenWidth)
    this.initTrHeight()
    this.loadClients()
    this.loadItems()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    updateScreenWidth() {
      this.isMobile = window.innerWidth <= 768 || window.innerWidth <= 1024 // 768px is a common breakpoint for 'sm' screens
    },
    truncateText(text) {
      const maxLength = 7 // Set your desired max length
      return text.length > maxLength ? `${text.substring(0, maxLength)}..` : text
    },
    quantityEnter() {
      this.addNewItem()
      document.getElementById('item').focus()
    },
    loadClients() {
      store
        .dispatch('common/fetchPosClients')
        .then(response => {
          const { clients } = response.data.data
          for (let i = 0; i < clients.length; i += 1) {
            this.clientOptions.push({ label: clients[i].name, value: clients[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadItems() {
      store
        .dispatch('common/fetchPosItems')
        .then(response => {
          const { items } = response.data.data
          this.offlineDB.items.clear()
          this.offlineDB.items.bulkAdd(items)
          for (let i = 0; i < items.length; i += 1) {
            this.itemOptions.push({ label: items[i].name, value: items[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    close() {
      if (this.$route.query.ref === 'view') {
        this.$router.push({ name: 'pos-sale-view', params: { id: this.$router.currentRoute.params.id } })
      } else {
        this.$router.push({ name: 'pos-sale-list' })
      }
    },
    async addNewItem() {
      if (this.itemId !== null && this.itemQuantity !== null) {
        this.$refs.formitem.style.overflow = 'hidden'
        const selectedItem = await this.offlineDB.items.where({ id: this.itemId }).first()

        if (this.saleData.sale_items.some(obj => obj.item_id === this.itemId)) {
          this.$swal('Warning', 'Item already exists', 'warning')
          return
        }

        this.itemFormBlankItem.item_name = selectedItem.name
        this.itemFormBlankItem.item_id = selectedItem.id
        this.itemFormBlankItem.price = Number(selectedItem.sale_price)
        this.itemFormBlankItem.quantity = Number(this.itemQuantity)
        this.itemFormBlankItem.unit = selectedItem.unit.name
        this.itemFormBlankItem.amount = Number(selectedItem.sale_price) * Number(this.itemQuantity)

        this.saleData.sale_items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.itemId = null
        this.itemQuantity = null

        this.calTotalAmount()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rowitem[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formitem.style.overflow = null
          }, 350)
        })
      }
    },
    removeItem(index) {
      this.saleData.sale_items.splice(index, 1)
      this.calTotalAmount()
      this.trTrimHeight(this.$refs.rowitem[0].offsetHeight)
    },
    calItemAmount(itemId, price, quantity) {
      const index = this.saleData.sale_items.findIndex((obj => obj.item_id === itemId))
      const amount = Number(price) * Number(quantity)
      this.saleData.sale_items[index].amount = amount
      this.calTotalAmount()
    },
    calTotalAmount() {
      this.saleData.total_amount = this.saleData.sale_items.reduce((a, b) => +a + +b.amount, 0)
      this.saleData.net_amount = this.saleData.total_amount - this.saleData.discount
    },
    initTrHeight() {
      this.trSetHeight(null)
      // console.log(this.SaleData.sale_items)
      // console.log(this.$refs.formitem.scrollHeight)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formitem.scrollHeight)
      })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const itemFormBlankItem = {
      item_name: '',
      item_id: null,
      price: 0,
      quantity: 0,
      amount: 0,
    }

    const saleData = ref({
      sale_date: null,
      client_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_receipt: 0,
      employee_name: '',
      reference: '',
      remark: '',
      branch_name: '',
      status: null,
      sale_region_id: 1, // default sale region
      sale_items: [],
      sale_receipts: [],
    })

    const router = context.root.$router
    const saleId = router.currentRoute.params.id

    store.dispatch('sale/fetchOne', { id: saleId })
      .then(response => {
        const dataItems = []
        const dataReceipts = []
        const responseItems = response.data.data.sale_items
        const responseReceipts = response.data.data.sale_receipts
        for (let i = 0; i < responseItems.length; i += 1) {
          dataItems.push({
            item_id: responseItems[i].item_id,
            item_name: responseItems[i].item.name,
            price: responseItems[i].price,
            quantity: responseItems[i].quantity,
            unit: responseItems[i].item.unit,
            amount: responseItems[i].amount,
          })
        }
        for (let i = 0; i < responseReceipts.length; i += 1) {
          dataReceipts.push({
            receipt_date: responseReceipts[i].receipt_date,
            amount: responseReceipts[i].amount,
            reference: responseReceipts[i].reference,
          })
        }
        const responseData = {
          id: response.data.data.id,
          sale_date: response.data.data.sale_date,
          client_id: parseInt(response.data.data.client_id, 10),
          total_amount: response.data.data.total_amount,
          discount: response.data.data.discount,
          net_amount: response.data.data.net_amount,
          total_receipt: response.data.data.total_receipt,
          employee_name: response.data.data.employee_name,
          reference: response.data.data.reference,
          remark: response.data.data.remark,
          branch_name: response.data.data.branch_name,
          status: response.data.data.status,
          sale_items: dataItems,
          sale_receipts: dataReceipts,
        }
        saleData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          saleData.value = undefined
        }
      })

    const onSubmit = () => {
      // receipt and amount is same for POS, cash sale
      saleData.value.total_receipt = saleData.value.net_amount
      saleData.value.sale_receipts[0].amount = saleData.value.net_amount

      const data = JSON.parse(JSON.stringify(saleData.value))
      store.dispatch('sale/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'pos-sale-view', params: { id: router.currentRoute.params.id } })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      saleData,
      itemFormBlankItem,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff;
}
</style>
